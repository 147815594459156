<template>
  <cw-page
    icon="done_all"
    class="car-loan__customer-ready"
  >
    <template #page-title>
      <translate>
        Application completed
      </translate>
    </template>
    <v-form @submit.prevent="submit">
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <p class="mb-0">
            <translate>
              The loan will be automatically paid to the dealership. The first invoice will be sent
              14 days before the first due date. Your car dealer will continue from here.
            </translate>
          </p>
          <p class="mb-0">
            <translate>
              You can manage your loans easily at MySaldo.
              In MySaldo you can see your available additional services such as the
              Saldo Safe loan insurance that insures your payment ability in case of
              circumstances such as unemployment.
            </translate>
          </p>
        </v-col>
      </v-row>
    </v-form>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          submit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CwCarLoanApplicationCustomerReady',

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Customer Ready',
      };

      try {
        await this.submitForm({
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
